import {
  Routes,
  Route,
  BrowserRouter as Router,
  useLocation,
} from 'react-router-dom';
import Main from './components/Main';
import Enquiry from './components/Enquiry';
import FaqDetails from './components/FaqSection/FaqDetails';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { apiUrl } from './services/commonService';
import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import Breadcrumbs from './components/Breadcrumbs';
import Logout from './components/Logout/Logout';
import { useCookies } from 'react-cookie';

function App() {
  const [pageLoading, setPageLoading] = useState(false);
  const [cookies] = useCookies(['authorization'], {
    doNotParse: true,
  });

  useEffect(() => {
    const queryParam = new URLSearchParams(document.location.search);
    let codeDetail = queryParam.get('code');
    if (
      cookies.authorization &&
      sessionStorage.getItem('accessToken') === null &&
      codeDetail === null
    ) {
      setPageLoading(true);
      window.location.href = `${apiUrl()}okta/login`;
    }
    if (!cookies.authorization) {
      sessionStorage.clear();
    }
  }, [cookies]);

  useEffect(() => {
    const queryParam = new URLSearchParams(document.location.search);
    let codeDetail = queryParam.get('code');
    let state = queryParam.get('state');
    if (codeDetail) {
      setPageLoading(true);
      getAccessToken(codeDetail, state);
    }
  }, []);

  const getAccessToken = async (ssoUrlCode: any, state: any) => {
    window.history.pushState({}, document.title, '/');
    var config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${apiUrl()}okta/oauth?code=${ssoUrlCode}&state=${state}`,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    await axios(config)
      .then(function (response) {
        sessionStorage.setItem('accessToken', response.data.access_token);
        sessionStorage.setItem('idToken', response.data.id_token);
        getUsersDetails();
      })
      .catch(function (error) {
        console.log(error);
        setPageLoading(false);
      });
  };
  const getUsersDetails = async () => {
    window.history.pushState({}, document.title, '/');
    var config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${apiUrl()}okta/userinfo`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
      },
    };

    await axios(config)
      .then(function (response) {
        sessionStorage.setItem('UsersDetails', JSON.stringify(response.data));
        setPageLoading(false);
        if (window.location.origin !== 'https://web-help.cisco.com') {
          document.cookie = 'authorization =LoggedIn';
        }
      })
      .catch(function (error) {
        console.log(error);
        setPageLoading(false);
      });
  };
  const CurrentRoute = () => {
    const location = useLocation();
    return (
      <div id='fw-c-skiplinks'>
        <ul className='fw-c-skiplinks__container'>
          {location.pathname === '/' && (
            <>
              <li>
                <a id='fw-c-skiplinks__skiplink--content' href='#fw-c-content'>
                  Skip to main content
                </a>
              </li>
              <li>
                <a id='fw-c-skiplinks__skiplink--Faq_Inquiry' href='#faqDiv'>
                  Skip to FAQ / Submit Inquiry
                </a>
              </li>
            </>
          )}

          {location.pathname === '/inquiry' && (
            <li>
              <a id='fw-c-skiplinks__skiplink--content' href='#fw-c-content'>
                Skip to main content
              </a>
            </li>
          )}

          {location.pathname === '/faq' && (
            <li>
              <a id='fw-c-skiplinks__skiplink--content' href='#fw-c-content'>
                Skip to main content
              </a>
            </li>
          )}

          <li>
            <a id='fw-c-skiplinks__skiplink--footer' href='#fw-c-footer'>
              Skip to footer
            </a>
          </li>
        </ul>
      </div>
    );
  };
  return (
    <>
      {pageLoading ? (
        <div
          _ngcontent-c3=''
          className='spinnerText'
          style={{ marginTop: '80px', marginBottom: '80px' }}
        >
          <div _ngcontent-c3='' className='spinner'></div>
        </div>
      ) : (
        <>
          <Router>
            <CurrentRoute />
            <div className='root responsivegrid'>
              <div className='aem-Grid aem-Grid--12 aem-Grid--default--12 '>
                {window.location.pathname !== '/logout' && <Header />}
                <div className='muse-divider'>
                  <div className='muse-divider-text'></div>
                </div>
                <div
                  className='responsivegrid aem-GridColumn aem-GridColumn--default--12'
                  id='fw-c-content'
                >
                  <div className='aem-Grid aem-Grid--12 aem-Grid--default--12 '>
                    {window.location.pathname !== '/logout' && <Breadcrumbs />}
                    <ScrollToTop />

                    <Routes>
                      <Route path='/' element={<Main />} />
                      <Route path='/inquiry' element={<Enquiry />} />
                      <Route path='/faq' element={<FaqDetails />} />
                      <Route path='/logout' element={<Logout />} />
                    </Routes>
                  </div>
                </div>
                {window.location.pathname !== '/logout' && <Footer />}
              </div>
            </div>
          </Router>
        </>
      )}
    </>
  );
}

export default App;
