import { Link, useLocation } from 'react-router-dom';
import { BsChevronRight } from 'react-icons/bs';

export default function Breadcrumbs() {
  const location = useLocation();

  const crumbs = location.pathname
    .split('/')
    .filter((crumb) => crumb !== '')
    .map((crumb) => {
      //currentLink +=  `/${crumb}`
      if (crumb === 'inquiry') {
        crumb = 'Submit Inquiry';
      } else if (crumb === 'faq') {
        crumb = 'FAQ';
      }
      return (
        <div className='crumb' key={crumb}>
          <Link to={'/'} tabIndex={0}>
            Home <BsChevronRight aria-hidden='true' style={{ top: '3px', position: 'relative' }} />
          </Link>
          <p> {crumb}</p>
        </div>
      );
    });
  return <div className='breadcrumbs' role='navigation' aria-label='Breadcrumb'>{crumbs}</div>;
}
