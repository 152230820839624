import React, { useEffect } from 'react';
import '../assets/css/general.css';
import Resource from './Resource';
import FaqSection from './FaqSection/FaqSection';
import Search from './Search';
import { apiUrl, validateAccessToken } from '../services/commonService';
import { Helmet } from 'react-helmet';

export default function Main() {
  useEffect(() => {
    setTimeout(() => validateToken(), 1000);
  }, []);

  const validateToken = async () => {
    try {
      await validateAccessToken();
    } catch (error) {
      console.warn('Anauthorised ', error);
      sessionStorage.clear();
      window.location.href = `${apiUrl()}okta/login`;
    }
  };

  return (
    <>
      <Helmet>
        <title>Web Help Portal</title>
      </Helmet>
      <div className='container' id='container'>
        <div className='row serachClass' id='SearchDiv'>
          <Search></Search>
        </div>
        <div
          className='row resourceClass muse-mt-140'
          id='resourceDiv'
        >
          <Resource></Resource>
        </div>
        <div className='row faqMainDiv' id='faqDiv'>
          <FaqSection></FaqSection>
        </div>
      </div>
    </>
  );
}
