import './ToolTip.css';
const ToolTip = (props: any) => {
  let classNm = props?.styleClass ? props?.styleClass : '';
  return (
    <>
      <span id={props.id} className={`tooltiptext ${classNm}`} role='tooltip'>{props?.tooltipText}</span>
    </>
  );
};

export default ToolTip;
