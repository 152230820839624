import { useEffect } from 'react';
import './logoutStyle.css';

export default function Logout() {

  let safeURL;
  useEffect(() =>{
    safeURL = new URL(window.location.origin);
    let anchorTag = document.querySelector('#windowURL') as HTMLAnchorElement;
    if(anchorTag){
      anchorTag.href = safeURL.href;
    }
  },[]);

  return (
    <>
      <div id='fw-mb'>
        <div id='fw-mb-w1'>
          <div id='popup-wrapper'>
            <div id='fw-banner'>
              <div id='popup-banner'>
                <div id='popup-logo'>
                  <a
                    id='popup-logo-link'
                    href='https://www.cisco.com/c/en/us/index.html'
                  >
                    Cisco.com Home
                  </a>
                </div>
              </div>
            </div>
            <div id='popup-content'>
              <div id='popup-content-padding'>
                <div id='popup-content-padding' className='sitecopy'>
                  <div id='logoutsuccess'>
                    <p className='title'>Thank you for visiting.</p>
                    <p className='message'>
                      You are now logged out of all Cisco.com pages and
                      applications.
                    </p>
                    <p>For increased security, please close your browser.</p>
                    <p>
                      Return to{' '}
                      <a id="windowURL">
                        {window.location.host}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
