import { useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { FaXmark } from 'react-icons/fa6';
import './SearchBar.css';
import { getSearched } from '../../services/commonService';
import { Link } from 'react-router-dom';
import '../SearchBar/SearchResultsList.css';
import '../SearchBar/SearchResult.css';

export const SearchBar = ({ setResults, setTypedValue, typedValue, results }: any) => {
  const [input, setInput] = useState('');
  const [focusId, setFocusId] = useState('');
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [closeIconIndex, setCloseIconIndex] = useState<number>(-1);
  const [ariaExpanded, setAriaExpanded] = useState(false);

  useEffect(() => {
    const handleKeypress = (event: any) => {
      if (!results.resourcesData && !results.faqData) return;
      const searchData = [...results.resourcesData, ...results.faqData]

      const updateFocus = (newIndex: number) => {
        setCurrentIndex(newIndex);
        setFocusId(searchData[newIndex].id);

        // Scroll the selected item into view
        const selectedItem = document.getElementById(searchData[newIndex].id);
        if (selectedItem) {
          selectedItem.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest', // Ensures the item is visible, can also use 'start' or 'end'
            inline: 'nearest',
          });
          selectedItem.focus(); // Set accessibility focus
        }
      };

      const index = searchData.findIndex((element: any) => element.id === focusId);
      let nextIndex = index;
      switch (event.key) {
        case 'ArrowDown':
          if (searchData.length === 0) {
            setFocusId('no-results-found');
            setCurrentIndex(0);
            setCloseIconIndex(-1);
            event.preventDefault();
            const noDataFound = document.getElementById('no-results-found');
            if (noDataFound) {
              noDataFound.focus();
            }
          } else {
            nextIndex = currentIndex === -1 ? 0 : (index + 1) % searchData.length;
            event.preventDefault();
            if (nextIndex >= 0 && nextIndex < searchData.length) updateFocus(nextIndex);
          }
          break;

        case 'ArrowUp':
          nextIndex = index - 1 >= 0 ? index - 1 : searchData.length - 1;
          event.preventDefault();
          if (nextIndex >= 0 && nextIndex < searchData.length) updateFocus(nextIndex);
          break;

        case 'Enter':
          const activeElement = document.activeElement;
          if (activeElement?.id === 'search-close') {
            setCurrentIndex(0);
            setCloseIconIndex(-1);
            setFocusId('');
            setInput('');
            setResults([]);
            setAriaExpanded(false);
            return;
          }
          else if (index >= 0) {
            const selectedItem = searchData[index];
            if (selectedItem.href) {
              window.open(selectedItem.href, '_blank'); // Open in new tab
            } else {
              window.location.href = '/faq';
            }
          }
          break;
        case 'Tab':
          event.preventDefault();
          if (closeIconIndex === -1) {
            const closeIcon = document.getElementById('search-close');
            if (closeIcon) {
              closeIcon.focus();
              closeIcon.setAttribute('aria-label', 'Close search');
              setCloseIconIndex(0);
            }
          } else if (searchData.length > 0) {
            updateFocus(0);
            setCloseIconIndex(-1);
          } else {
            setFocusId('no-results-found');
            setCurrentIndex(0);
            setCloseIconIndex(-1);
            const noDataFound = document.getElementById('no-results-found');
            if (noDataFound) {
              noDataFound.focus();
            }
          }
          break;
        case 'Escape':
          setCloseIconIndex(-1);
          setInput('');
          setResults([]);
          setAriaExpanded(false);
          event.preventDefault();
          break;
      }
    }
    window.addEventListener('keydown', handleKeypress, true); // Capture phase

    return () => {
      window.removeEventListener('keydown', handleKeypress, true); // Capture phase
    };
  }, [currentIndex, focusId, results, setResults, closeIconIndex]);

  const fetchData = async (value: string) => {
    const getSearchedsData = await getSearched(value);
    const results =
      getSearchedsData.data.faq?.map((element: { id: any; ques: any }) => ({
        id: element.id,
        name: element.ques,
      })) || [];

    const resourceResults =
      getSearchedsData.data.resources?.map(
        (element: { id: any; title: any; href: any }) => ({
          id: element.id,
          name: element.title,
          href: element.href,
        })
      ) || [];

    const combinedData = {
      resourcesData: resourceResults,
      faqData: results,
    };

    let searchData = combinedData.resourcesData.concat(combinedData.faqData);
    if (searchData.length > 0) setFocusId(searchData[0].id);
    setCurrentIndex(-1);
    setResults(combinedData);
    setTypedValue(value);
  };

  const handleChange = (value: string) => {
    setInput(value);
    if (value.length > 0) {
      fetchData(value);
      setAriaExpanded(true);
    }
    if (value.length === 0) {
      setInput('');
      setResults([]);
      setAriaExpanded(false);
    }
  };

  return (
    <div>
      <div className='input-wrapper'>
        <FaSearch
          aria-hidden='true'
          aria-label='Decorative graphic'
          role='img'
          id='search-icon'
        />
        <input
          aria-autocomplete="list"
          aria-controls="search-result-list"
          aria-expanded={ariaExpanded}
          value={input}
          onChange={(e) => handleChange(e.target.value)}
          aria-label='search'
          placeholder='Search'
          role="combobox"
          maxLength={275}
        />
        <div
          id='search-close'
          style={{ color: 'grey', cursor: 'pointer' }}
          role='button'
          tabIndex={closeIconIndex}
          aria-label='Close search'
          onClick={() => {
            setCloseIconIndex(-1);
            setInput('');
            setResults([]);
            setAriaExpanded(false);
          }}
        >
          <FaXmark style={{ height: '19px' }} />
        </div>
      </div>

      {/* combined SearchResult and SearchResultList components to a single file */}
      <div className='results-list' id='search-result-list' role="list" aria-label="Search results" aria-describedby="search-instructions">
        {results.length === 0 || ((!results.resourcesData || results.resourcesData.length === 0) &&
          (!results.faqData || results.faqData.length === 0) && (
            <div role="status" aria-live="polite">
              <div className='search-result' id='no-results-found' tabIndex={0}>
                <a target='_blank' rel='noreferrer' href={`https://search.cisco.com/search?locale=enUS&query=${typedValue}&bizcontext=&mode=text&clktyp=enter&autosuggest=false`} style={{ width: "100%", display: "block" }}>No result found, search on <p style={{ "color": "var(--muse-text-accent) !important", "display": "inline-block" }}>Cisco.com</p> click here!</a>
              </div>
            </div>
          ))}
        {results.resourcesData && results.resourcesData.length > 0 && (
          <div role="group" aria-label="Resources section" aria-labelledby="resources-label">
            <div id="resources-label" className='muse-text-style-30 muse-font-weight-600 searchBoxLabelResource'>
              Resources
            </div>
            {results.resourcesData.map((result: any, index: number) => {
              return (
                <div role="listitem" className="search-result" key={result?.id} id={result?.id} tabIndex={index === currentIndex ? 0 : -1}>
                  <div style={{ width: '95%' }} id={`id-${result?.id}`}>
                    <a aria-label={`${result?.name}, Opens in a new tab`} href={result?.href} target='_blank' rel='noreferrer'>{result?.name}</a>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {results.faqData && results.faqData.length > 0 && (
          <div role="group" aria-label="FAQ section" aria-labelledby="faq-label">
            <div id="faq-label" className='muse-text-style-30  muse-font-weight-600 searchBoxLabelFaq'>
              FAQ's
            </div>
            {results.faqData.map((faqData: any, index: number) => {
              return (
                <div role="listitem" className='search-result' key={faqData?.id} id={faqData?.id} tabIndex={index === currentIndex ? 0 : -1}>
                  <div style={{ width: '95%' }}>
                    <Link aria-label={`${faqData?.name}, Opens in the same tab`} to={{ pathname: '/faq' }} state={{ idFromSearch: faqData }}>
                      {faqData?.name}
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <div id="search-instructions" style={{ display: 'none' }}>
        Use Up/Down arrow keys to navigate and Enter key to select.
      </div>
    </div>
  )
};
