import React, { useEffect } from 'react';
import './Modal.css';

interface ModalBoxProps {
  ModalOpen: any;
  From?:any;
}

const ModalBox: React.FC<ModalBoxProps> = ({ ModalOpen, From }) => {

  const handleClose = () => {
    //props.handleOpen = false
    ModalOpen(false)
  }

  useEffect(() => {
    document.body.classList.add('no-scroll');

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);


  const renderModal = (From: string) => {
    switch (From) {
      case 'footer': 
        return ( 
          <div className="modal-overlay" aria-modal="true">
            <div className="modal-content">
              <div tabIndex={0} style={{display: 'flex'}}>
                  <img style={{width: '67px', height: '35px'}} 
                      src="https://pdx1.qualtrics.com/WRQualtricsSiteIntercept/Graphic.php?IM=IM_9ufPO8lmTRbqSH4"
                      alt="Cisco Logo" />
                  <button onClick={handleClose} className="modal-close-button" style={{marginLeft: 'auto'}}>
                      <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='19'
                      height='19'
                      fill='none'
                      >
                      <path
                          fill='currentColor'
                          d='m17.5.793-8 8-8-8-.707.707 8 8-8 8 .707.707 8-8 8 8 .707-.707-8-8 8-8L17.5.793Z'
                      ></path>
                      </svg>
                  </button>
              </div>
              <iframe src={"https://ciscocx.qualtrics.com/jfe/form/SV_bwrmeoKrBHYxOyW?Ref=%2F"} width="100%" height="500px" title="Feedback Form" />
            </div>
          </div> 
        )
      default :
        return        <div className='muse-modal--partial modelStyle'>
                        <div className='muse-modal-dialog'>
                        <button onClick={handleClose} className='muse-modal-dialog-close' style={{"background": "#fff"}} tabIndex={0}>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='19'
                            height='19'
                            fill='none'
                          >
                            <path
                              fill='currentColor'
                              d='m17.5.793-8 8-8-8-.707.707 8 8-8 8 .707.707 8-8 8 8 .707-.707-8-8 8-8L17.5.793Z'
                            ></path>
                          </svg>
                        </button>
                        <div className='muse-modal-dialog-header'>
                          <h1 className='muse-headline-heading muse-mt-0'>
                            Submitted Successfully
                          </h1>
                          <p className='muse-text-style-body muse-mt-40'>
                            Thank you for submitting the inquiry.
                          </p>
                        </div>
                        <div className='muse-modal-dialog-content-slot muse-mt-100'>
                          <p className='muse-text-style-body-reduced'>
                            We will get back to you soon!
                          </p>
                        </div>
                        <div className='muse-modal-dialog-footer muse-mt-100 '>
                          <button onClick={handleClose} className='muse-button-primary' tabIndex={0}>
                            Close
                          </button>
                        </div>
                      </div>
                      <div className='muse-modal-scrim'></div>
                    </div>
    }
  }

  return (
    <div
      data-testid='myModal'
      id='myModal'
      className={`modal ${ModalOpen ? 'openModal' : ''}`}
    >
      {renderModal(From)}
    </div>
  );
};

export default ModalBox;
