import { useState } from 'react';
import ModalBox from './Modal/ModalBox';

export default function Footer() {
  const [showContactFotter, setContactFooter] = useState(false);
  const [showSecFotter, setSecFooter] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpen = (val: boolean) => {
    setIsModalOpen(val);
  };

  const handleFeedback = async(e: any) => {
    e.preventDefault();
    handleOpen(true);
  };

  return (
    <div className='footer aem-GridColumn aem-GridColumn--default--12'>
      <footer id='fw-c-footer' className='fw-c-footer'>
        <div
          className='fw-c-footer__content-bed'
          data-config-metrics-group='Footer'
        >
          <div className='fw-c-footer__links --primary'>
            <h3 className='fw-c-footer__links-title'>
              <button
                id='fw-c-footer__links-button--primary'
                className={`${showContactFotter ? 'fw-c-footer__links-button' :'fw-c-footer__links-button --open'}`}
                aria-controls='fw-c-footer__links-list--primary'
                onClick={() => {
                  setContactFooter(!showContactFotter);
                }}
              >
                Quick Links
              </button>
            </h3>
            <div
              id='fw-c-footer__links-list--primary'
              className='fw-c-footer__links-list'
              aria-labelledby='fw-c-footer__links-button--primary'
              aria-hidden='false'
              hidden = {showContactFotter}
            >
              <div
                data-c-version='REI:Text (TO BE DEPRECATED):V0.0.0'
                id='text-b0fa5101e7'
                className='cmp-text cds-typography '
              >
                <ul>
                  <li>
                    <a
                      href='https://www.cisco.com/c/en/us/about.html'
                      target='_blank'
                      rel='noreferrer'
                    >
                      About Cisco
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://www.cisco.com/site/us/en/about/contact-cisco/index.html?contactpageID=footer-link'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Contact Us
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://www.cisco.com/c/en/us/about/careers.html'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Careers
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://www.cisco.com/site/us/en/partners/connect-with-a-partner/index.html'
                      target='_blank'
                      rel='noreferrer'
                      >
                      Connect with a partner
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className='fw-c-footer__separator --primary'>
            <div id='separator-71f551a389' className='cmp-separator'>
              <hr className='cmp-separator__horizontal-rule' />
            </div>
          </div>

          <div className='fw-c-footer__links --secondary'>
            <h3 className='fw-c-footer__links-title'>
              <button
                id='fw-c-footer__links-button--secondary'
                className={`${showSecFotter ? 'fw-c-footer__links-button' :'fw-c-footer__links-button --open'}`}
                aria-controls='fw-c-footer__links-list--secondary'
                onClick={() => {
                  setSecFooter(!showSecFotter);
                }}
              >
                Resources and Legal
              </button>
            </h3>
            <div
              id='fw-c-footer__links-list--secondary'
              className='fw-c-footer__links-list'
              aria-labelledby='fw-c-footer__links-button--secondary'
              aria-hidden='false'
              hidden={showSecFotter}
            >
              <div
                data-c-version='REI:Text (TO BE DEPRECATED):V0.0.0'
                id='text-26a12a886d'
                className='cmp-text cds-typography '
              >
                <ul>
                  <li>
                    <a
                      id='feedback-us-en'
                      href='https://ciscocx.qualtrics.com/jfe/form/SV_bwrmeoKrBHYxOyW?Ref=%2F'
                      onClick={handleFeedback}
                    >
                      Feedback
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://www.cisco.com/c/en/us/about/help.html'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Help
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://www.cisco.com/c/en/us/about/legal/terms-conditions.html'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Terms &amp; Conditions
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://www.cisco.com/c/en/us/about/legal/privacy-full.html'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Privacy Statement
                    </a>
                  </li>
                  <li>
                    <a
                      id='privacy-manager'
                      href='https://www.cisco.com/c/en/us/about/legal/privacy-full.html#cookies'
                      data-config-metrics-group='Footer'
                      data-config-metrics-title='Consent Preferences - Footer'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Cookies
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://www.cisco.com/c/en/us/about/accessibility.html'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Accessibility
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://www.cisco.com/c/en/us/about/legal/trademarks.html'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Trademarks
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://www.cisco.com/c/dam/en_us/about/supply-chain/cisco-modern-slavery-statement.pdf'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Supply Chain Transparency
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://newsroom.cisco.com/c/r/newsroom/en/us/index.html'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Newsroom
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://www.cisco.com/c/en/us/about/sitemap.html'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Sitemap
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className='fw-c-footer__separator --secondary'>
            <div id='separator-ddeb3e4336' className='cmp-separator'>
              <hr className='cmp-separator__horizontal-rule' />
            </div>
          </div>

          <ul className='fw-c-footer__links --social'>
            <li>
              <a
                className='fw-c-footer__social-channel --channel-facebook'
                href='https://www.facebook.com/Cisco/'
                title='Facebook'
                data-config-metrics-item='Facebook'
                target='_blank'
                rel='noreferrer'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  height='20px'
                  width='20px'
                  viewBox='0 0 20 20'
                >
                  <circle fill='#8e8e8e' r='10' cy='10' cx='10'></circle>
                  <path
                    fill='#fff'
                    d='M 13.9 12.9 l 0.4 -2.9 l -2.8 0 l 0 -1.9 c 0 -0.8 0.4 -1.6 1.6 -1.6 l 1.3 0 l 0 -2.5 c 0 0 -1.1 -0.2 -2.2 -0.2 c -2.3 0 -3.8 1.4 -3.8 3.9 l 0 2.2 l -2.5 0 l 0 2.9 l 2.5 0 l 0 7 c 0.5 0.1 1 0.1 1.6 0.1 c 0.5 0 1.1 0 1.6 -0.1 l 0 -7 l 2.3 0 z'
                  ></path>
                </svg>
              </a>
            </li>

            <li>
              <a
                className='fw-c-footer__social-channel --channel-twitter'
                href='https://twitter.com/Cisco/'
                title='X'
                data-config-metrics-item='X'
                target='_blank'
                rel='noreferrer'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  height='20px'
                  width='20px'
                  viewBox='0 0 20 20'
                >
                  <path
                    fill='#8e8e8e'
                    d='M 15.751 0.961 L 18.818 0.961 L 12.118 8.619 L 20 19.039 L 13.828 19.039 L 8.995 12.719 L 3.464 19.039 L 0.395 19.039 L 7.561 10.848 L 0 0.961 L 6.328 0.961 L 10.698 6.738 L 15.751 0.961 Z M 14.675 17.203 L 16.374 17.203 L 5.405 2.7 L 3.581 2.7 L 14.675 17.203 Z'
                  ></path>
                </svg>
              </a>
            </li>

            <li>
              <a
                className='fw-c-footer__social-channel --channel-linkedin'
                href='https://www.linkedin.com/company/cisco'
                title='LinkedIn'
                data-config-metrics-item='LinkedIn'
                target='_blank'
                rel='noreferrer'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  height='20px'
                  width='20px'
                  viewBox='0 0 20 20'
                >
                  <path
                    fill='#8e8e8e'
                    d='M2,0h16c1.1,0,2,0.9,2,2v16c0,1.1-0.9,2-2,2H2c-1.1,0-2-0.9-2-2V2C0,0.9,0.9,0,2,0z'
                  ></path>
                  <path
                    fill='#fff'
                    d='M6.7,15.8H4.2V6.7h2.5V15.8z M16.7,15.8h-2.5v-4.7c0-2.8-3.3-2.6-3.3,0v4.7H8.3V6.7h2.5v1.5c1.2-2.2,5.8-2.3,5.8,2.1V15.8z'
                  ></path>
                  <circle fill='#fff' cx='5.5' cy='4.13' r='1.5'></circle>
                </svg>
              </a>
            </li>

            <li>
              <a
                className='fw-c-footer__social-channel --channel-youtube'
                href='https://www.youtube.com/user/cisco'
                title='YouTube'
                data-config-metrics-item='YouTube'
                target='_blank'
                rel='noreferrer'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  height='20px'
                  width='20px'
                  viewBox='0 0 20 20'
                >
                  <path
                    fill='#8e8e8e'
                    d='M 19.6 5.2 c -0.1 -0.4 -0.3 -0.8 -0.6 -1.1 c -0.3 -0.3 -0.7 -0.5 -1.1 -0.7 C 16.2 3 10 3 10 3 S 3.8 3 2.2 3.4 C 1.8 3.5 1.4 3.8 1.1 4.1 C 0.8 4.4 0.5 4.8 0.4 5.2 C 0 6.8 0 10 0 10 s 0 3.3 0.4 4.8 c 0.1 0.4 0.3 0.8 0.6 1.1 c 0.3 0.3 0.7 0.5 1.1 0.7 c 1.6 0.4 7.8 0.4 7.8 0.4 s 6.2 0 7.8 -0.4 c 0.4 -0.1 0.8 -0.3 1.1 -0.7 c 0.3 -0.3 0.5 -0.7 0.6 -1.1 C 20 13.3 20 10 20 10 S 20 6.8 19.6 5.2 z'
                  ></path>
                  <path fill='#fff' d='M 8 13 l 5.2 -3 L 8 7.1 V 13 z'></path>
                </svg>
              </a>
            </li>

            <li>
              <a
                className='fw-c-footer__social-channel --channel-instagram'
                href='https://www.instagram.com/cisco/'
                title='Instagram'
                data-config-metrics-item='Instagram'
                target='_blank'
                rel='noreferrer'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink'
                  height='20px'
                  width='20px'
                  viewBox='0 0 20 20'
                >
                  <defs>
                    <linearGradient id='b'>
                      <stop offset='0' stopColor='#3771c8'></stop>
                      <stop stopColor='#3771c8' offset='0.1'></stop>
                      <stop
                        offset='1'
                        stopColor='#6600ff'
                        stopOpacity='0'
                      ></stop>
                    </linearGradient>
                    <linearGradient id='a'>
                      <stop offset='0' stopColor='#ffdd55'></stop>
                      <stop offset='0.1' stopColor='#ffdd55'></stop>
                      <stop offset='0.5' stopColor='#ff543e'></stop>
                      <stop offset='1' stopColor='#c837ab'></stop>
                    </linearGradient>
                    <radialGradient
                      id='c'
                      cx='158.429'
                      cy='578.088'
                      r='65'
                      gradientUnits='userSpaceOnUse'
                      gradientTransform='matrix(0, -0.304863, 0.283624, 0, -158.646866, 69.833641)'
                      xlinkHref='#a'
                    ></radialGradient>
                    <radialGradient
                      id='d'
                      cx='147.694'
                      cy='473.455'
                      r='65'
                      gradientUnits='userSpaceOnUse'
                      gradientTransform='matrix(0.026755, 0.133624, -0.550943, 0.110315, 253.544876, -70.524132)'
                      xlinkHref='#b'
                    ></radialGradient>
                    <path
                      id='logo'
                      d='M 10 0 C 7.3 0 6.9 0 5.9 0.1 C 4.8 0.1 4.1 0.3 3.5 0.5 C 2.8 0.8 2.2 1.1 1.7 1.7 C 1.1 2.2 0.8 2.8 0.5 3.4 C 0.3 4.1 0.1 4.8 0.1 5.9 C 0 6.9 0 7.3 0 10 C 0 12.7 0 13.1 0.1 14.1 C 0.1 15.2 0.3 15.9 0.5 16.6 C 0.8 17.2 1.1 17.8 1.7 18.3 C 2.2 18.9 2.8 19.2 3.4 19.5 C 4.1 19.7 4.8 19.9 5.9 19.9 C 6.9 20 7.3 20 10 20 C 12.7 20 13.1 20 14.1 19.9 C 15.2 19.9 15.9 19.7 16.6 19.5 C 17.2 19.2 17.8 18.9 18.3 18.3 C 18.9 17.8 19.2 17.2 19.5 16.6 C 19.7 15.9 19.9 15.2 19.9 14.1 C 20 13.1 20 12.7 20 10 C 20 7.3 20 6.9 19.9 5.9 C 19.9 4.8 19.7 4.1 19.5 3.5 C 19.2 2.8 18.9 2.2 18.3 1.7 C 17.8 1.1 17.2 0.8 16.6 0.5 C 15.9 0.3 15.2 0.1 14.1 0.1 C 13.1 0 12.7 0 10 0 Z M 9.1 1.8 C 9.4 1.8 9.7 1.8 10 1.8 C 12.7 1.8 13 1.8 14 1.9 C 15 1.9 15.5 2.1 15.9 2.2 C 16.4 2.4 16.7 2.6 17 3 C 17.4 3.3 17.6 3.6 17.8 4.1 C 17.9 4.5 18.1 5 18.1 6 C 18.2 7 18.2 7.3 18.2 10 C 18.2 12.7 18.2 13 18.1 14 C 18.1 15 17.9 15.5 17.8 15.9 C 17.6 16.4 17.4 16.7 17 17 C 16.7 17.4 16.4 17.6 15.9 17.8 C 15.5 17.9 15 18.1 14 18.1 C 13 18.2 12.7 18.2 10 18.2 C 7.3 18.2 7 18.2 6 18.1 C 5 18.1 4.5 17.9 4.1 17.8 C 3.6 17.6 3.3 17.4 3 17 C 2.6 16.7 2.4 16.4 2.2 15.9 C 2.1 15.5 1.9 15 1.9 14 C 1.8 13 1.8 12.7 1.8 10 C 1.8 7.3 1.8 7 1.9 6 C 1.9 5 2.1 4.5 2.2 4.1 C 2.4 3.6 2.6 3.3 3 2.9 C 3.3 2.6 3.6 2.4 4.1 2.2 C 4.5 2.1 5 1.9 6 1.9 C 6.9 1.8 7.2 1.8 9.1 1.8 Z M 15.3 3.5 C 14.7 3.5 14.1 4 14.1 4.7 C 14.1 5.3 14.7 5.9 15.3 5.9 C 16 5.9 16.5 5.3 16.5 4.7 C 16.5 4 16 3.5 15.3 3.5 Z M 10 4.9 C 7.2 4.9 4.9 7.2 4.9 10 C 4.9 12.8 7.2 15.1 10 15.1 C 12.8 15.1 15.1 12.8 15.1 10 C 15.1 7.2 12.8 4.9 10 4.9 Z M 10 6.7 C 11.8 6.7 13.3 8.2 13.3 10 C 13.3 11.8 11.8 13.3 10 13.3 C 8.2 13.3 6.7 11.8 6.7 10 C 6.7 8.2 8.2 6.7 10 6.7 Z'
                    ></path>
                  </defs>
                  <use className='primary' fill='#8e8e8e' href='#logo'></use>
                  <use className='secondary' fill='url(#d)' href='#logo'></use>
                </svg>
              </a>
            </li>
          </ul>

          <div className='fw-c-footer__copyright'>
            ©<span className='fw-c-footer__copyright-year'>2024</span> Cisco
            Systems, Inc.
          </div>
        </div>
      </footer>
      {isModalOpen && <ModalBox ModalOpen={handleOpen} From='footer'></ModalBox>}
    </div> 
  );
}
