import React from 'react'

export default function ErrorLabel(props: any) {

  return (
    <div id={props.id} className={props.class} role="alert" aria-live="assertive">
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='12'
        height='12'
        fill='none'
        role='img'
        aria-label={props.error}
      >
        <path
          fill='inherit'
          d='M6.375.75a5.625 5.625 0 1 0 0 11.25 5.625 5.625 0 0 0 0-11.25Zm.375 9H6V9h.75v.75Zm0-2.25H6V3.75h.75V7.5Z'
        ></path>
      </svg>
      {props.error}
    </div>
  )
}
