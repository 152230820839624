import { useEffect, useState } from 'react';
import { SlArrowDown, SlArrowUp } from 'react-icons/sl';
import HtmlOutput from '../../assets/js/HtmlOutput';
import { updateFaqCount } from '../../services/commonService';
import './Accordion.css';

type ClickedStatusType = {
  [key: number]: boolean;
};
export default function Accordion(props: any) {
  const [clickedStatus, setClickedStatus] = useState<ClickedStatusType>({});

  const handleClick = (index: any, dt: any) => {
    if (clickedStatus[index] !== true) {
      setFaqCount(dt.id);
    }
    setClickedStatus((prevStatus: any) => ({
      // ...prevStatus,
      [index]: !prevStatus[index], // Toggle the clicked state for the item
    }));
  };
  const setFaqCount = async (qId: any) => {
    try {
      await updateFaqCount(qId);
    } catch {}
  };
  useEffect(() => {
    if(props.dtFromSearch !== undefined  ){
      setClickedStatus({[props.dtFromSearch.result.id]: false})
      handleClick(props.dtFromSearch.result.id, props.dtFromSearch.result)
      const element = document.getElementById(`${props.dtFromSearch.result.id}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  },[])
  
  return (
    <>
      {props.details.length > 0 &&
        props.details.map((dt: any, index: any) => {
          return (
            <div className='muse-accordion' key={dt.id}>
              <div className='muse-divider'>
                <div className='muse-divider-text'></div>
              </div>
              <div
                key={dt.id}
                className={
                  clickedStatus[dt.id]
                    ? 'muse-accordion-section--expanded'
                    : 'muse-accordion-section'
                }
              >
                <div
                  className='muse-accordion-title'
                  tabIndex={0} 
                  role="button"
                  aria-expanded={clickedStatus[dt.id] ? "true" : "false"}
                  aria-controls={`section-content-${dt.id}`} id={dt?.id}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      e.preventDefault();
                      handleClick(dt.id, dt);
                    }
                  }}
                  onClick={() => handleClick(dt.id, dt)}
                >
                  <div className='muse-text-style-title-reduced'>{dt.ques}</div>
                  <span className='muse-accordion-icon-right'>
                    {clickedStatus[dt.id] ? <SlArrowUp /> : <SlArrowDown />}
                  </span>
                </div>
                <div className='muse-accordion-body' role="region" aria-labelledby={`section-header-${dt.id}`} id={`section-content-${dt.id}`}>
                  {props.view === 'mobile' ? (
                    dt.ans
                  ) : (
                    <HtmlOutput htmlContent={dt.ans} />
                  )}
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
}
