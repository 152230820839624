import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  apiUrl,
  sendEnqueryEmail,
  validateAccessToken,
} from '../services/commonService';
import ErrorLabel from './Validation/ErrorLabel';
import {
  ucFirst,
  validateAlphabet,
  validateEmail,
} from '../assets/js/validator';
import ModalBox from './Modal/ModalBox';
import './Enquiry.css';
import { Helmet } from 'react-helmet';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from 'react-google-recaptcha-v3';
// import ReCAPTCHA from 'react-google-recaptcha';


export default function Enquiry() {
  const callLoginUrl = () => {
    window.location.href = `${apiUrl()}okta/login`;
  };
  const [userDetails] = useState<any | null>(
    sessionStorage.getItem('UsersDetails')
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [inputValues, setInputValues] = useState<any | ''>({
    firstName: JSON.parse(userDetails)?.given_name
      ? JSON.parse(userDetails)?.given_name
      : '',
    lastName: JSON.parse(userDetails)?.family_name
      ? JSON.parse(userDetails)?.family_name
      : '',
    email: JSON.parse(userDetails)?.email ? JSON.parse(userDetails)?.email : '',
    issueCategory: '',
    issueSubCategory: '',
    description: '',
  });

  const [error, setError] = useState<any>({
    firstName: '',
    lastName: '',
    email: '',
    issueCategory: '',
    issueSubCategory: '',
    description: '',
  });

  const [rules, setRules] = useState<any>({
    firstName: {
      required: true,
      alphabets: false,
      minlength: 1,
      maxlength: 50,
      fieldName: 'First Name',
    },
    lastName: {
      required: true,
      alphabets: false,
      minlength: 1,
      maxlength: 50,
      fieldName: 'Last Name',
    },
    email: { required: true, email: true, fieldName: 'Email', maxlength: 40 },
    issueCategory: {
      required: true,
      fieldName: 'Issue Category',
    },
    issueSubCategory: {
      required: true,
      fieldName: 'Issue Subcategory',
    },
    description: {
      required: false,
      alphabets: false,
      minlength: 4,
      maxlength: 32000,
      fieldName: 'Description',
    },
  });

  const [checkLogin, setCheckLogin] = useState<any>('');

  const [showNotification, setNotification] = useState<Boolean>(false);
  const [showSubLinks, setSubLinks] = useState<any>('');
  const [showValue, setValue] = useState<any>('');
  const [captchaValue, setcaptchaValue] = useState<any>('');

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setValue(value);
    if (name === 'issueCategory') {
      inputValues.issueSubCategory = '';
      inputValues.description = '';
      error.issueSubCategory = '';
      // error.issueSubCategory = 'Please Enter ' + rules[name]?.fieldName;
      error.description = 'Please Enter Description';
      // if (value !== 'Other Categories') {
      //   error.description = '';
      // }
      setNotification(false)
    }

    if(value === 'Networking Academy' || value === 'Training and Certification (Learning@Cisco)' || value === 'Contract Changes and Updates'){
      setNotification(true)
      setSubLinks(getsubCategoryLink(event.target.value));
    }else{
      setNotification(false)
    }

    setInputValues({ ...inputValues, [name]: value.trimStart() });

    if (rules[name].required === true && value === '') {
      setError({ ...error, [name]: 'Please Enter ' + rules[name]?.fieldName });
    } else if (rules[name]?.email && !validateEmail(value)) {
      setError({ ...error, [name]: 'Please Enter Valid Email' });
    } else if (
      rules[name].alphabets &&
      !validateAlphabet(value) &&
      value !== ''
    ) {
      setError({ ...error, [name]: 'Only alphabets are allowed here' });
    } else if (
      rules[name]?.minlength &&
      value.length < rules[name]?.minlength &&
      value !== ''
    ) {
      setError({
        ...error,
        [name]:
          ucFirst(rules[name]?.fieldName) +
          ' is too short to be valid,minimum field length is ' +
          rules[name]?.minlength,
      });
    } else if (
      rules[name]?.maxlength &&
      value.length > rules[name]?.maxlength &&
      value !== ''
    ) {
      setError({
        ...error,
        [name]:
          ucFirst(rules[name]?.fieldName) +
          ' is too long to be valid,maximum field length is ' +
          rules[name]?.maxlength,
      });
    } else {
      setError({ ...error, [name]: '' });
    }
  };
  const handleForm = () => {
    let validateOk = true;
    if (
      inputValues.issueCategory === 'Other Categories' &&
      inputValues.description === ''
    ) {
      validateOk = false;
    }
    if (
      inputValues.issueCategory !== 'Other Categories' &&
      inputValues.issueSubCategory === ''
    ) {
      validateOk = false;
    }
    if (
      inputValues.firstName !== '' &&
      inputValues.lastName !== '' &&
      inputValues.email !== '' &&
      inputValues.issueCategory !== '' &&
      error.firstName === '' &&
      error.lastName === '' &&
      error.email === '' &&
      error.description === '' &&
      validateOk === true
    ) {
      sendEmail();
    }
  };

  const getsubCategoryLink = (Links: string | undefined) => {
    switch (Links) {
      case "Contract Changes and Updates":
        return 'https://customerservice.cloudapps.cisco.com'
      case "Networking Academy": 
        return "https://www.netacad.com/support/"
      case "Training and Certification (Learning@Cisco)":
        return 'https://certsupport.cisco.com/'        
    }
  }

  // Define function that will open the modal
  const handleOpen = (val: boolean) => {
    setIsModalOpen(val);
  };

  const disabledButton = () => {
    let element = document.getElementById('btn_submit') as HTMLInputElement;
    if (element) {
      element.classList.add('muse-disabled-button');
      element.disabled = true;
    }
  };
  const enabledButton = () => {
    let element = document.getElementById('btn_submit') as HTMLInputElement;
    if (element) {
      element.classList.remove('muse-disabled-button');
      element.disabled = false;
    }
  };

  const sendEmail = async () => {
    disabledButton();
    try {
      const popularFaqData = await sendEnqueryEmail(inputValues, captchaValue);
      if (
        popularFaqData.status === 200 &&
        popularFaqData.data.Message === 'Email Send Successfully'
      ) {
        setInputValues({
          firstName: JSON.parse(userDetails)?.given_name
            ? JSON.parse(userDetails)?.given_name
            : '',
          lastName: JSON.parse(userDetails)?.family_name
            ? JSON.parse(userDetails)?.family_name
            : '',
          email: JSON.parse(userDetails)?.email
            ? JSON.parse(userDetails)?.email
            : '',
          issueCategory: '',
          issueSubCategory: '',
          description: '',
        });
        handleOpen(true);
      } else {
        alert(popularFaqData.data.Message);
      }
    } catch (error) {
      console.warn('Error in sending mail, please try after sometime. ', error);
    }
  };
  useEffect(() => {
    setRules({
      ...rules,
      description: {
        required: true,
        alphabets: false,
        minlength: 4,
        maxlength: 32000,
        fieldName: 'Description',
      },
    });
  }, [inputValues]);
  useEffect(() => {
    let validateOk = true;
    if (
      inputValues.issueCategory === 'Other Categories' &&
      inputValues.description === ''
    ) {
      validateOk = false;
    }
    if (
      inputValues.issueCategory !== 'Other Categories' &&
      inputValues.issueSubCategory === ''
    ) {
      validateOk = false;
    }

    if (
      inputValues.firstName !== '' &&
      inputValues.lastName !== '' &&
      inputValues.email !== '' &&
      inputValues.issueCategory !== '' &&
      error.firstName === '' &&
      error.lastName === '' &&
      error.email === '' &&
      error.description === '' &&
      validateOk === true &&
      showNotification !== true
    ) {
      enabledButton();
    } else {
      disabledButton();
    }
    setCheckLogin(sessionStorage.getItem('accessToken'));
  }, [inputValues.issueCategory, inputValues.description, inputValues.issueSubCategory, inputValues.firstName, inputValues.lastName, inputValues.email, error.firstName, error.lastName, error.email, error.description]);
  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 1000);
    setTimeout(() => validateToken(), 1000);
  }, []);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Tab') {
        document.body.classList.add('keyboard-focused');
      }
    };

    const handleMouseDown = () => {
      document.body.classList.remove('keyboard-focused');
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('mousedown', handleMouseDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  const validateToken = async () => {
    try {
      await validateAccessToken();
    } catch (error) {
      console.warn('Anauthorised ', error);
      sessionStorage.clear();
      window.location.href = `${apiUrl()}okta/login`;
    }
  };

  function onCpatchaChange(value: string | null): void {
    setcaptchaValue(value)
  }
  // const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  // const handleCaptchaChange = (token: string | null) => {
  //   setCaptchaToken(token);
  // };
  return (
    <>
      <Helmet>
        <title>Web Help Portal</title>
      </Helmet>
      <div className='container'>
        <div className='row padding160 mobileViewEnquiry'>
          <div
            className='muse-mt-60 muse-mb-100'
            style={{ display: 'block', height: 'auto' }}
          >
            <div className='cmp-title cds-typography  '>
              <h1>Contact Us</h1>
              <p className='muse-text-50 muse-mt-40'>
                Fill out the form and will reach out soon
              </p>
              <div
                className={
                  checkLogin ? 'displayNone' : 'displayBlock info muse-mt-40'
                }
              >
                <p className='informationPara'>
                  You can get help faster and easier if you{' '}
                  <a className='loginAnchar' onClick={callLoginUrl} tabIndex={0}>
                    sign in.
                  </a>
                </p>
              </div>
              <div
                className={
                  checkLogin
                    ? 'borderBottom muse-mt-100'
                    : 'borderBottom muse-mt-60'
                }
              ></div>
            </div>
            <div className='muse-mt-150 minMaxWidth550'>
              <div
                className={`muse-input ${
                  error?.firstName && 'muse-input--error'
                } `}
              >
                <div
                  className='muse-input-wrapper'
                  style={checkLogin ? { background: '#f9f8f8' } : {}}
                >
                  <label className='muse-input-label' htmlFor='firstName'>
                    First Name*
                  </label>
                  <input
                    type='text'
                    className='muse-input-field maxWidth95'
                    name='firstName'
                    id='firstName'
                    maxLength={50}
                    value={inputValues.firstName}
                    onChange={(e) => handleChange(e)}
                    autoComplete='off'
                    disabled={checkLogin ? true : false}
                    autoFocus
                    aria-required='true'
                    aria-label='First Name'
                  />
                </div>
                {error?.firstName && (
                  <ErrorLabel
                    class='muse-input-caption'
                    error={error?.firstName}
                  />
                )}
              </div>
              <div className='muse-input muse-mt-100'>
                <div
                  className={`muse-input ${
                    error?.lastName && 'muse-input--error'
                  } `}
                >
                  <div
                    className='muse-input-wrapper'
                    style={checkLogin ? { background: '#f9f8f8' } : {}}
                  >
                    <label className='muse-input-label' htmlFor='lastName'>
                      Last Name*
                    </label>
                    <input
                      type='text'
                      className='muse-input-field maxWidth95'
                      name='lastName'
                      id='lastName'
                      maxLength={50}
                      value={inputValues.lastName}
                      onChange={(e) => handleChange(e)}
                      autoComplete='off'
                      disabled={checkLogin ? true : false}
                      aria-required='true'
                      aria-label='Last Name'
                    />
                  </div>
                  {error?.lastName && (
                    <ErrorLabel
                      class='muse-input-caption'
                      error={error?.lastName}
                    />
                  )}
                </div>
              </div>
              <div className='muse-input muse-mt-100'>
                <div
                  className={`muse-input ${
                    error?.email && 'muse-input--error'
                  } `}
                >
                  <div
                    className='muse-input-wrapper'
                    style={checkLogin ? { background: '#f9f8f8' } : {}}
                  >
                    <label className='muse-input-label' htmlFor='email'>
                      Email Address*
                    </label>
                    <input
                      type='text'
                      className='muse-input-field maxWidth95'
                      name='email'
                      id='email'
                      maxLength={41}
                      value={inputValues.email}
                      onChange={(e) => handleChange(e)}
                      autoComplete='off'
                      disabled={checkLogin ? true : false}
                      aria-required='true'
                      aria-label='Email Address'
                    />
                  </div>
                  {error?.email && (
                    <ErrorLabel
                      class='muse-input-caption'
                      error={error?.email}
                    />
                  )}
                </div>
              </div>
              <div className='muse-select muse-mt-100'>
                <div
                  className={`muse-input ${
                    error?.issueCategory && 'muse-select--error'
                  } `}
                >
                  <div className='muse-select-wrapper'>
                    <label
                      className='muse-select-label'
                      htmlFor='issueCategory'
                    >
                      Issue Category*
                    </label>
                    <select
                      name='issueCategory'
                      id='issueCategory'
                      className='muse-select-field'
                      //onChange={e => setSelectedCategory(e.target.value)}
                      value={inputValues.issueCategory}
                      onChange={(e) => handleChange(e)}
                      aria-required='true'
                      aria-describedby="issueCategoryError"
                    >
                      <option value=''>Type</option>
                      <option value='Cisco.com Profile / Account and Password Issues'>
                        Cisco.com Profile / Account and Password Issues{' '}
                      </option>
                      <option value='Contracts and Contract Association'>
                        Contracts & Contract Association
                      </option>
                      <option value='Software Download'>
                        Software Download
                      </option>
                      <option value='Networking Academy, Training and Certifications'>
                          Networking Academy, Training & Certifications
                      </option>
                      <option value='Other Categories'>Other Categories</option>
                    </select>
                  </div>
                  {error?.issueCategory && (
                    <ErrorLabel
                      id='issueCategoryError'
                      class='muse-select-caption'
                      error={error?.issueCategory}
                    />
                  )}
                </div>
              </div>
              {inputValues.issueCategory ===
                'Cisco.com Profile / Account and Password Issues' && (
                <div className='muse-select muse-mt-100'>
                  <div
                    className={`muse-input ${
                      error?.issueSubCategory && 'muse-select--error'
                    } `}
                  >
                    <div className='muse-select-wrapper'>
                      <label
                        className='muse-select-label'
                        htmlFor='issueSubCategory'
                      >
                        Issue Subcategory*
                      </label>
                      <select
                        name='issueSubCategory'
                        id='issueSubCategory'
                        className='muse-select-field'
                        defaultValue={
                          inputValues.issueSubCategory
                            ? inputValues.issueSubCategory
                            : ''
                        }
                        onChange={(e) => handleChange(e)}
                        aria-required='true'
                        aria-label='Issue Subcategory'
                        aria-describedby="issueSubCategoryError"
                      >
                        <option value=''>Type</option>
                        <option value='Password Reset and Recovery'>
                          Password Reset & Recovery
                        </option>
                        <option value='Account Login Error Messages'>
                          Account Login Error Messages
                        </option>
                      </select>
                    </div>
                    {error?.issueSubCategory && (
                      <ErrorLabel
                        id='issueSubCategoryError'
                        class='muse-select-caption'
                        error={error?.issueSubCategory}
                      />
                    )}
                  </div>
                </div>
              )}

              {inputValues.issueCategory ===
                'Contracts and Contract Association' && (
                <div className='muse-select muse-mt-100'>
                  <div
                    className={`muse-input ${
                      error?.issueSubCategory && 'muse-select--error'
                    } `}
                  >
                    <div className='muse-select-wrapper'>
                      <label
                        className='muse-select-label'
                        htmlFor='issueSubCategory'
                      >
                        Issue Subcategory*
                      </label>
                      <select
                        name='issueSubCategory'
                        id='issueSubCategory'
                        className='muse-select-field'
                        defaultValue={
                          inputValues.issueSubCategory
                            ? inputValues.issueSubCategory
                            : ''
                        }
                        onChange={(e) => handleChange(e)}
                        aria-describedby="issueSubCategoryError"
                      >
                        <option value=''>Type</option>
                        <option value='Add Contract to User'>
                          Add Contract to User
                        </option>
                        <option value='Remove Contract from User'>
                          Remove Contract from User
                        </option>
                        <option value='Contract Changes and Updates'>
                          Contract Changes & Updates
                        </option>
                      </select>
                    </div>
                    {error?.issueSubCategory && (
                      <ErrorLabel
                        id='issueSubCategoryError'
                        class='muse-select-caption'
                        error={error?.issueSubCategory}
                      />
                    )}
                  </div>
                </div>
              )}

              {inputValues.issueCategory === 'Networking Academy, Training and Certifications' && (
                <div className='muse-select muse-mt-100'>
                  <div
                    className={`muse-input ${
                      error?.issueSubCategory && 'muse-select--error'
                    } `}
                  >
                    <div className='muse-select-wrapper'>
                      <label
                        className='muse-select-label'
                        htmlFor='issueSubCategory'
                      >
                        Issue Subcategory*
                      </label>
                      <select
                        name='issueSubCategory'
                        id='issueSubCategory'
                        className='muse-select-field'
                        defaultValue={
                          inputValues.issueSubCategory
                            ? inputValues.issueSubCategory
                            : ''
                        }
                        onChange={(e) => handleChange(e)}
                        aria-describedby="issueSubCategoryError"
                      >
                        <option value=''>Type</option>
                        <option value='Networking Academy'>
                          Networking Academy
                        </option>
                        <option value='Training and Certification (Learning@Cisco)'>
                          Training and Certification (Learning@Cisco)
                        </option>
                      </select>
                    </div>
                    {error?.issueSubCategory && (
                      <ErrorLabel
                        id='issueSubCategoryError'
                        class='muse-select-caption'
                        error={error?.issueSubCategory}
                      />
                    )}
                  </div>
                </div>
              )}      

              {inputValues.issueCategory === 'Software Download' && (
                <div className='muse-select muse-mt-100'>
                  <div
                    className={`muse-input ${
                      error?.issueSubCategory && 'muse-select--error'
                    } `}
                  >
                    <div className='muse-select-wrapper'>
                      <label
                        className='muse-select-label'
                        htmlFor='issueSubCategory'
                      >
                        Issue Subcategory*
                      </label>
                      <select
                        name='issueSubCategory'
                        id='issueSubCategory'
                        className='muse-select-field'
                        defaultValue={
                          inputValues.issueSubCategory
                            ? inputValues.issueSubCategory
                            : ''
                        }
                        onChange={(e) => handleChange(e)}
                        aria-describedby="issueSubCategoryError"
                      >
                        <option value=''>Type</option>
                        <option value='Find Software Image'>
                          Find Software Image
                        </option>
                        <option value='Access to Download Software Image'>
                          Access to Download Software Image
                        </option>
                      </select>
                    </div>
                    {error?.issueSubCategory && (
                      <ErrorLabel
                        id='issueSubCategoryError'
                        class='muse-select-caption'
                        error={error?.issueSubCategory}
                      />
                    )}
                  </div>
                </div>
              )}

              {(!['Contract Changes and Updates','Networking Academy','Training and Certification (Learning@Cisco)'].includes(showValue)) && (
                <div className='muse-input muse-mt-100'>
                  <div
                    className={`muse-input ${
                      error?.description && 'muse-input--error'
                    } `}
                  >
                    <div
                      className='muse-input-wrapper'
                      style={{ height: '100px' }}
                    >
                      <label className='muse-input-label' htmlFor='description'>
                        Description*
                      </label>
                      <textarea
                        className='muse-input-field'
                        name='description'
                        id='description'
                        placeholder='Please enter description with minimum 4 characters'
                        maxLength={32001}
                        value={inputValues.description}
                        onChange={(e) => handleChange(e)}
                        style={{ resize: 'none', maxWidth: '95%' }}
                        aria-label='Description'
                        aria-describedby='Please enter description with minimum 4 characters'
                      />
                    </div>
                    {error?.description && (
                      <ErrorLabel
                        class='muse-input-caption'
                        error={error?.description}
                      />
                    )}
                  </div>
                </div>
              )}

              <GoogleReCaptchaProvider
                container={{
                  // optional to render inside custom element
                  element: '',
                  parameters: {
                    badge: 'inline', // optional, default undefined
                    theme: 'light', // optional, default undefined
                  },
                }}
                useEnterprise={true}
                reCaptchaKey={'6Le9JTgqAAAAABGc3bLX0_MuZXL5vbieSicsbYrt' || ''}
              >
                <GoogleReCaptcha
                  onVerify={(value) => {
                      if (!captchaValue) {
                      onCpatchaChange(value);
                    }
                  }}
                />
              </GoogleReCaptchaProvider>

              {showNotification && (<div className="subNotification" id="subCategoryLinkNotification">
                <div id="subCategoryLinkNotificationinnerDiv" className='subLinkNotificationinnerDiv'>
                  <p>For support related to <b>{inputValues.issueSubCategory}</b>, please visit: </p>
                  <p><a href={showSubLinks} rel="noreferrer" target='_blank'>{showSubLinks}</a></p>
                </div>  
              </div>)}

              <div>
                {/*onClick={handleOpen}*/}
                <button
                  onClick={handleForm}
                  className='muse-button-primary muse-mt-150 cursorPointer muse-disabled-button'
                  id='btn_submit'
                >
                  Submit Inquiry
                </button>
                <Link
                  to='/'
                  className='muse-button-secondary muse-ml-100 cursorPointer'
                >
                  Cancel
                </Link>
              </div>
              
              {isModalOpen && <ModalBox ModalOpen={handleOpen} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
