import { useEffect, useState } from 'react';
import { ReactComponent as SearchLogos } from '../assets/svg/searchLady.svg';
import { ReactComponent as SearchLogos1 } from '../assets/svg/newSearchHomePage.svg';
import { SearchBar } from './SearchBar/SearchBar';
import { SearchResultsList } from './SearchBar/SearchResultsList';

export default function Search() {
  const [results, setResults] = useState([]);
  const [typedValue, setTypedValue] = useState([]);
  const [checkLogin, setCheckLogin] = useState<boolean>(false);
  const [userDetails] = useState<any | null>(
    sessionStorage.getItem('UsersDetails')
  );

  useEffect(() => {
    setCheckLogin(sessionStorage.getItem('accessToken') ? true : false);
  });

  return (
    <>
      <div
        className='muse-card'
        style={{ width: '100%', height: ' 100%', resize: 'both' }}
      >
        {/* <SearchLogos></SearchLogos> */}
        <SearchLogos1 className="searchLady"></SearchLogos1>
        <div
          className={`${
            checkLogin ? 'welcomelabelInputLoggedIn' : 'welcomelabelInput'
          }`}
        >
          {checkLogin ? (
            <h1>
              Hi {JSON.parse(userDetails).first_name}! Welcome to Common Inquiry
              Portal
            </h1>
          ) : (
            <h1>Welcome to the Web Help Portal</h1>
          )}
          <div className='muse-input muse-mt-50'>
            <div className=''>
              <SearchBar setResults={setResults} setTypedValue={setTypedValue} typedValue={typedValue} results={results} />

              {/* {results && Object.keys(results).length > 0 && (
                <SearchResultsList results={results} typedValue={typedValue}/>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
