import { useEffect, useState } from 'react';
import { apiUrl } from '../services/commonService';
import { ReactComponent as CiscoLogo } from '../assets/svg/cisco.svg';

export default function Header() {
  const [showLoggedInUserDetails, setLoggedInUserDetails] = useState(false);
  const [showContact, setContact] = useState(false);

  const [checkLogin, setCheckLogin] = useState<boolean>(false);
  const [userDetails] = useState<any | null>(
    sessionStorage.getItem('UsersDetails')
  );
  const [showHan, setHam] = useState(false);
  const [ariaExpanded, setAriaExpanded] = useState(false);
  const [ariaExpandedLogin, setAriaExpandedLogin] = useState(false);

  const closeLoggedInDiv = () => {
    setLoggedInUserDetails(false);
    focusOnLogin();
    setAriaExpandedLogin(!ariaExpandedLogin);
  };
  const focusOnLogin = () => {
    const loginLink = document.getElementById('button-c7ec524dd72');
    loginLink?.focus();
  };
  const callLoginUrl = () => {
    setAriaExpandedLogin(!ariaExpandedLogin);

    if (checkLogin === false) {
      window.location.href = `${apiUrl()}okta/login`;
    } else {
      setLoggedInUserDetails(!showLoggedInUserDetails);
      let userDet = document.getElementById('fw-c-header__dropdown--login');
      if (showLoggedInUserDetails) {
        userDet?.classList.add('--active');
      } else {
        userDet?.classList.remove('--active');
      }
      //userDet?.classList.add('--active');
      setContact(false);
      if (showHan === true) {
        setHam(!showHan);
      }
    }
  };

  const contactOpen = () => {
    setAriaExpanded(!ariaExpanded);
    setContact(!showContact);
    setLoggedInUserDetails(false);
    if (showHan === true) {
      setHam(!showHan);
    }
  };
  const closeContact = () => {
    setAriaExpanded(!ariaExpanded);
    setContact(!showContact);
    focusOnContact();
  };
  const focusOnLogoutBtn = () => {
    const logoutBtn = document.getElementById('LogoutBtn');
    logoutBtn?.focus();
  };
  const focusOnContact = () => {
    const contactLink = document.getElementById('button-b5fc56c4ef1');
    contactLink?.focus();
  };
  useEffect(() => {
    setCheckLogin(sessionStorage.getItem('accessToken') ? true : false);
  });

  const logoutUser = () => {
    let useremail: any = JSON.parse(userDetails)?.email;
    sessionStorage.clear();
    document.cookie =
      'authorization =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    window.location.href = `${apiUrl()}logout?email_id=${useremail}`;
  };

  const setHamburger = () => {
    setHam(!showHan);
    let userDet = document.getElementById('fw-c-header__dropdown--login');
    userDet?.classList.remove('--active');
  };

  let safeURL;

  useEffect(() => {
    safeURL = new URL(window.location.origin);
    let anchorTag = document.querySelector(
      '#fw-c-header__logo'
    ) as HTMLAnchorElement;
    if (anchorTag) {
      anchorTag.href = safeURL.href;
    }

    const handleTabPress = (event: KeyboardEvent) => {
      if (event.key === 'Tab') {
        const activeElement = document.activeElement;

        if (activeElement?.ariaLabel === 'Close') {
          setContact(false);
          setAriaExpanded(false);
          setAriaExpandedLogin(false);
          setLoggedInUserDetails(false);
        }
      }
      if (event.key === 'Escape') {
        setContact(false);
        setAriaExpanded(false);
        setLoggedInUserDetails(false);
        const isContactMenuActive = document
          .querySelector('.fw-c-header__dropdown.--buy')
          ?.classList.contains('--active');

        if (isContactMenuActive) {
          focusOnContact();
        }

        const isLoginMenuActive = document
          .querySelector('.fw-c-header__dropdown.--login')
          ?.classList.contains('--active');
        setAriaExpandedLogin(false);
        if (isLoginMenuActive) {
          focusOnLogin();
        }
      }
      if (event.key === 'Enter') {
        setTimeout(() => {
          const contactUsLink = document.querySelector<HTMLAnchorElement>(
            '.cds-c-static-list__link.contact-us'
          );
          contactUsLink?.focus();
          focusOnLogoutBtn();
        }, 10);
      }
    };

    window.addEventListener('keydown', handleTabPress);

    return () => {
      window.removeEventListener('keydown', handleTabPress);
    };
  }, []);

  return (
    <>
      <div className='header aem-GridColumn aem-GridColumn--default--12'>
        <header className='fw-c-header'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='36'
            height='36'
            viewBox='0 0 36 36'
            style={{ display: 'none' }}
          >
            <defs>
              <path
                id='svg-x'
                fill='none'
                fillRule='evenodd'
                stroke='inherit'
                strokeLinecap='round'
                strokeWidth='inherit'
                d='M30.26 6.495L5.505 31.25M5.495 6.495L30.25 31.25'
              ></path>
            </defs>
          </svg>
          <div className='fw-c-header__masthead'>
            <a
              className='fw-c-header__logo'
              id='fw-c-header__logo'
              title='Cisco.com Web-Help'
              aria-labelledby='fw-c-header__logo-title'
            >
              <CiscoLogo />
            </a>

            <div
              className={`fw-c-header__nav --has-partner-accordion ${showHan ? '--active' : ''
                } highlightLink `}
              role='navigation'
            >
              <div
                data-c-version='REI:Accordion (TO BE DEPRECATED):V0.0.0'
                id='accordion-825b090c07'
                className='cmp-accordion'
                data-placeholder-text='false'
              >
                <div
                  className='cmp-accordion__item'
                  data-cmp-hook-accordion='item'
                  id='accordion-825b090c07-item-80272c0e79'
                >
                  <div className='cmp-accordion__header'>
                    <a
                      id='accordion-825b090c07-item-80272c0e79-button'
                      className='cmp-accordion__button'
                      data-cmp-hook-accordion='button'
                      href='https://www.cisco.com/c/en/us/products/index.html'
                      target='_blank'
                      rel='noreferrer'
                      aria-label='Products and Services, Opens in a new tab'
                    >
                      <span className='cmp-accordion__title'>
                        Products and Services
                      </span>
                      {/* <span className="cmp-accordion__icon"></span> */}
                    </a>
                  </div>
                </div>
                <div
                  className='cmp-accordion__item'
                  data-cmp-hook-accordion='item'
                  id='accordion-825b090c07-item-f57cb5a340'
                >
                  <div className='cmp-accordion__header'>
                    <a
                      id='accordion-825b090c07-item-f57cb5a340-button'
                      className='cmp-accordion__button'
                      data-cmp-hook-accordion='button'
                      href='https://www.cisco.com/site/us/en/solutions/index.html'
                      target='_blank'
                      rel='noreferrer'
                      aria-label='Solutions, Opens in a new tab'
                    >
                      <span className='cmp-accordion__title'>Solutions</span>
                      {/* <span className="cmp-accordion__icon"></span> */}
                    </a>
                  </div>
                </div>
                <div
                  className='cmp-accordion__item'
                  data-cmp-hook-accordion='item'
                  id='accordion-825b090c07-item-803d0a291a'
                >
                  <div className='cmp-accordion__header'>
                    <a
                      id='accordion-825b090c07-item-803d0a291a-button'
                      className='cmp-accordion__button'
                      data-cmp-hook-accordion='button'
                      href='https://www.cisco.com/c/en/us/support/index.html'
                      target='_blank'
                      rel='noreferrer'
                      aria-label='Support, Opens in a new tab'
                    >
                      <span className='cmp-accordion__title'>Support</span>
                      {/* <span className="cmp-accordion__icon"></span> */}
                    </a>
                  </div>
                </div>
                <div
                  className='cmp-accordion__item'
                  data-cmp-hook-accordion='item'
                  id='accordion-825b090c07-item-3265282a49'
                >
                  <div className='cmp-accordion__header'>
                    <a
                      id='accordion-825b090c07-item-3265282a49-button'
                      className='cmp-accordion__button'
                      data-cmp-hook-accordion='button'
                      href='https://www.cisco.com/c/en/us/training-events.html'
                      target='_blank'
                      rel='noreferrer'
                      aria-label='Learn, Opens in a new tab'
                    >
                      <span className='cmp-accordion__title'>Learn</span>
                      {/* <span className="cmp-accordion__icon"></span> */}
                    </a>
                  </div>
                </div>
              </div>

              <div id='fw-c-header__nav-accordion--partner'>
                <div
                  data-c-version='REI:Accordion (TO BE DEPRECATED):V0.0.0'
                  id='partnerdropdown'
                  className='cmp-accordion'
                  data-placeholder-text='false'
                >
                  <div
                    className='cmp-accordion__item'
                    data-cmp-hook-accordion='item'
                    id='partnerdropdown-item-2d6591fff1'
                  ></div>
                </div>
              </div>

              <div className='fw-c-header__tophat --mobile'>
                <div
                  id='fw-c-header__button--buy--mobile'
                  className='fw-c-header__button --buy --mobile'
                >
                  <button
                    type='button'
                    id='button-b5fc56c4ef'
                    aria-expanded={ariaExpanded}
                    onClick={contactOpen}
                  >
                    <span className='cmp-button__text'>Contact</span>
                  </button>
                </div>

                <div
                  id='fw-c-header__button--language--mobile'
                  className='fw-c-header__button --language --mobile'
                ></div>
              </div>
            </div>

            <div className='fw-c-header__utilities'>
              <div
                id='fw-c-header__button--login--mobile'
                className={`fw-c-header__button --login --mobile ${checkLogin ? '--authenticated' : ''
                  }`}
              >
                <button
                  type='button'
                  id='button-c7ec524dd7'

                  onClick={() => callLoginUrl()}
                >
                  <span
                    className='cmp-button__icon cmp-button__icon--icon'
                    aria-hidden='true'
                  ></span>

                  <span className='cmp-button__text' data-cs-mask=''>
                    {checkLogin
                      ? JSON.parse(userDetails)?.given_name.charAt(0) +
                      JSON.parse(userDetails)?.last_name.charAt(0)
                      : 'Login'}
                  </span>
                </button>
              </div>
            </div>
            <button
              className={`fw-c-header__nav-button cmp-button ${showHan ? '--active' : ''
                } `}
              id='fw-c-header__nav-button'
              aria-haspopup='true'
              aria-expanded='false'
              aria-labelledby='fw-c-header__nav-button-label'
              onClick={setHamburger}
            >
              <div>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div
                className='fw-c-header__nav-button-label --active'
                id='fw-c-header__nav-button-label'
              >
                MENU
              </div>
              <div
                className='fw-c-header__nav-button-label'
                id='fw-c-header__nav-button-label--close'
              >
                CLOSE
              </div>
            </button>
          </div>
          <div className='fw-c-header__tophat'>
            <div
              id='fw-c-header__button--buy--desktop'
              className={`fw-c-header__button --buy --desktop ${showContact ? '--active' : ''
                }`}
            >
              <button
                type='button'
                id='button-b5fc56c4ef1'
                aria-expanded={ariaExpanded}
                onClick={contactOpen}
              >
                <span className='cmp-button__text'>Contact</span>
              </button>
            </div>
            <div
              id='fw-c-header__dropdown--buy'
              className={`fw-c-header__dropdown --buy ${showContact ? '--active' : ''
                }`}
            >
              <div className='root responsivegrid'>
                <div className='aem-Grid aem-Grid--12 aem-Grid--default--12 '>
                  <div className='containergrid container responsivegrid aem-GridColumn aem-GridColumn--default--12'>
                    <div
                      id='containergrid-e0d6f732a9'
                      className='cmp-containergrid cds-c-content-container__theme--light muse-py-default'
                      data-c-version='DM:Container-Grid:V1.5.3'
                      data-config-metrics-title='Contact utility menu'
                      data-placeholder-text='false'
                    >
                      <div
                        className='cds-l-grid cds-l-grid--full gridfullcol false'
                        id='innercontactUsMenu'
                      >
                        <div className='cmp-containergrid__col1 false'>
                          <div className='static'>
                            <div
                              id='static-b8eb833d2c'
                              className='cds-c-static-list'
                              data-c-version='DM:Static List:V2.1.0'
                            >
                              <ul className='cds-c-static-list__itemlist'>
                                <li className='cds-c-static-list__item '>
                                  <a
                                    className='cds-c-static-list__link contact-us'
                                    href='https://www.cisco.com/site/us/en/about/contact-cisco/index.html?contactpageID=utility-menu-link'
                                    target='_blank'
                                    rel='noreferrer'
                                    aria-label='Contact us, Opens in a new tab'
                                  >
                                    Contact us
                                  </a>
                                </li>
                                <li className='cds-c-static-list__item '>
                                  <a
                                    className='cds-c-static-list__link'
                                    href='https://www.cisco.com/c/en/us/buy.html?contactpageID=utility-menu-link'
                                    target='_blank'
                                    rel='noreferrer'
                                    aria-label='How to buy, Opens in a new tab'
                                  >
                                    How to buy
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <button
                type='button'
                className='fw-c-header__dropdown-close-button cmp-button'
                aria-label='Close'
                onClick={closeContact}
              >
                <span className='cmp-button__icon'>
                  <svg
                    xmlnsXlink='http://www.w3.org/1999/xlink'
                    xmlns='http://www.w3.org/2000/svg'
                    width='36'
                    height='36'
                    viewBox='0 0 36 36'
                  >
                    <use href='#svg-x' stroke='#1b1c1d' strokeWidth='2px'></use>
                  </svg>
                </span>
                <span className='cmp-button__text'>Close</span>
              </button>
            </div>
            <div
              id='fw-c-header__button--login--desktop'
              className={`fw-c-header__button --login --desktop --authenticated ${showLoggedInUserDetails ? '--active' : ''
                }`}
            >
              <button
                type='button'
                id='button-c7ec524dd72'
                {...(checkLogin ? { 'aria-expanded': ariaExpandedLogin } : {})}
                onClick={() => callLoginUrl()}
              >
                <span
                  className='cmp-button__icon cmp-button__icon--icon'
                  {...(checkLogin ? { 'aria-hidden': !ariaExpandedLogin } : {})}
                ></span>

                <span className='cmp-button__text' data-cs-mask=''>
                  {checkLogin
                    ? `${JSON.parse(userDetails)?.given_name} ${JSON.parse(userDetails)?.last_name.charAt(0)}.`
                    : 'Login'}
                </span>
              </button>
            </div>
          </div>
          <div className='fw-c-header__overlay'>
            <div
              id='fw-c-header__dropdown--search'
              className='fw-c-header__dropdown --search'
            >
              <div className='root responsivegrid'>
                <div className='aem-Grid aem-Grid--12 aem-Grid--default--12'>
                  <div className='global-search global search aem-GridColumn aem-GridColumn--default--12'></div>
                </div>
              </div>

              <button
                id='close'
                type='button'
                className='fw-c-header__dropdown-close-button cmp-button'
                aria-label='Close'
                onClick={closeLoggedInDiv}
              >
                <span className='cmp-button__icon'>
                  <svg
                    xmlnsXlink='http://www.w3.org/1999/xlink'
                    xmlns='http://www.w3.org/2000/svg'
                    width='36'
                    height='36'
                    viewBox='0 0 36 36'
                  >
                    <defs>
                      <path
                        id='svg-x'
                        fill='none'
                        fillRule='evenodd'
                        stroke='inherit'
                        strokeLinecap='round'
                        strokeWidth='inherit'
                        d='M30.26 6.495L5.505 31.25M5.495 6.495L30.25 31.25'
                      ></path>
                    </defs>
                  </svg>
                </span>
                <span className='cmp-button__text'>Close</span>
              </button>
            </div>

            <div
              id='fw-c-header__dropdown--login'
              className={
                showLoggedInUserDetails
                  ? 'fw-c-header__dropdown --login --active'
                  : 'fw-c-header__dropdown --login'
              }
              data-cs-mask=''
            >
              <div
                data-c-version='REI:Container (TO BE DEPRECATED):V0.0.0'
                id='container-54c6aa27a6'
                className='cmp-container'
              >
                <div className='aem-Grid aem-Grid--12 aem-Grid--default--12'>
                  <div className='responsivegrid aem-GridColumn aem-GridColumn--default--12'>
                    <div className='aem-Grid aem-Grid--12 aem-Grid--default--12'>
                      <div className='container responsivegrid aem-GridColumn aem-GridColumn--default--12'></div>
                      <div className='container responsivegrid aem-GridColumn aem-GridColumn--default--12'>
                        <div
                          data-c-version='REI:Container (TO BE DEPRECATED):V0.0.0'
                          id='container-fc7965c0ae'
                          className={`cmp-container ${checkLogin ? '--show' : ''
                            }`}
                          aria-label='login-authenticated'
                        >
                          <div className='container responsivegrid'>
                            <div
                              data-c-version='REI:Container (TO BE DEPRECATED):V0.0.0'
                              id='profile-name-bar'
                              className='cmp-container'
                            >
                              <div className='title'>
                                <div id='user-name' className='cmp-title'>
                                  <p className='cmp-title__text'>
                                    {JSON.parse(userDetails)?.given_name +
                                      ' ' +
                                      JSON.parse(userDetails)?.last_name.charAt(
                                        0
                                      ) +
                                      '.'}
                                  </p>
                                </div>
                              </div>
                              <div className='title'>
                                <div id='company-name' className='cmp-title'>
                                  <p className='cmp-title__text'>
                                    {JSON.parse(userDetails)?.company}
                                  </p>
                                </div>
                              </div>
                              <div className='title'>
                                <div id='email-address' className='cmp-title'>
                                  <p className='cmp-title__text'>
                                    {JSON.parse(userDetails)?.email}
                                  </p>
                                </div>
                              </div>
                              <div className='button bluebtn-underline'>
                                <a
                                  id='LogoutBtn'
                                  href='#'
                                  aria-label='Log out'
                                  target='_self'
                                  onClick={logoutUser}
                                >
                                  <span className='cmp-button__text'>
                                    Log out
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className='container responsivegrid'></div>
                          <div className='container responsivegrid'>
                            <div
                              data-c-version='REI:Container (TO BE DEPRECATED):V0.0.0'
                              id='login-drop-content-employee-links'
                              className={`cmp-container ${checkLogin ? '--show' : ''
                                }`}
                            >
                              <div className='text'>
                                <div
                                  data-c-version='REI:Text (TO BE DEPRECATED):V0.0.0'
                                  id='manage-links-employee'
                                  className='cmp-text cds-typography'
                                >
                                  <p>
                                    <a
                                      href='https://workday.cisco.com/'
                                      title='Manage employee profile (Workday)'
                                    >
                                      Manage employee profile (Workday)
                                    </a>
                                  </p>
                                  <p>
                                    <a
                                      href='https://id.cisco.com/ui/v1.0/profile-ui'
                                      title='Manage CCO profile'
                                    >
                                      Manage CCO profile
                                    </a>
                                  </p>
                                </div>
                              </div>
                              <div className='separator'>
                                <div
                                  id='separator-5338fd47fa'
                                  className='cmp-separator'
                                >
                                  <hr className='cmp-separator__horizontal-rule' />
                                </div>
                              </div>
                              <div className='title'>
                                <div
                                  id='login-employee-header'
                                  className='cmp-title'
                                >
                                  <p className='cmp-title__text'>
                                    Employee Connection
                                  </p>
                                </div>
                              </div>
                              <div className='text'>
                                <div
                                  data-c-version='REI:Text (TO BE DEPRECATED):V0.0.0'
                                  id='text-1c4e194947'
                                  className='cmp-text cds-typography'
                                >
                                  <ul>
                                    <li>
                                      <a
                                        href='http://wwwin.cisco.com/c/cec/index.html'
                                        title='Cisco Employee Connection'
                                      >
                                        Cisco Employee Connection
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href='https://directory.cisco.com/dir/'
                                        title='People Directory'
                                      >
                                        People Directory
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href='http://wwwin.cisco.com/c/cec/employee.html'
                                        title='Employee Services'
                                      >
                                        Employee Services
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href='http://wwwin.cisco.com/c/cec/support.html'
                                        title='Support &amp; Tools'
                                      >
                                        Support &amp; Tools
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href='https://cisco.sharepoint.com/_layouts/15/sharepoint.aspx'
                                        title='Employee Communities'
                                      >
                                        Employee Communities
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='container responsivegrid'></div>
                          <div className='container responsivegrid'></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <button
                type='button'
                className='fw-c-header__dropdown-close-button cmp-button'
                aria-label='Close'
                onClick={closeLoggedInDiv}
              >
                <span className='cmp-button__icon'>
                  <svg
                    xmlnsXlink='http://www.w3.org/1999/xlink'
                    xmlns='http://www.w3.org/2000/svg'
                    width='36'
                    height='36'
                    viewBox='0 0 36 36'
                  >
                    <use href='#svg-x' stroke='#1b1c1d' strokeWidth='2px'></use>
                  </svg>
                </span>
                <span className='cmp-button__text'>Close</span>
              </button>
            </div>
          </div>
        </header>
      </div>
    </>
  );
}
function focusOnLogin() {
  throw new Error('Function not implemented.');
}
